import React from "react";
import { StaticImage } from "gatsby-plugin-image";



const Clients = () => {
  return (
    <div className="">
      <div className="w-full px-6 py-20 m-auto max-w-7xl md:px-10 ">
        <div className="flex flex-col items-center justify-center w-full">
          <h1 className="md:text-4xl text-2xl montserrat text-[#454646] font-bold  text-center  mb-12 uppercase">
            OUR VALUED CLIENTS
          </h1>

          <div className="flex justify-center">
            <StaticImage
              src="../../images/vclients.png"
              alt=""
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
