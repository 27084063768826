import React from "react";
import Clients from "./Clients";
import ConnectCustomers from "./ConnectCustomers";
import DealsysTouch from "./DealsysTouch";
import Hero from "./Hero";
import Intro from "./Intro";
import PricingSupport from "./PricingSupport";
import Services from "./Services";
import Solutions from "./Solutions";

const DigitalDealer = () => {
  return (
    <div>
      <Hero />
      <Intro />
      <DealsysTouch />
      <PricingSupport />
      <Solutions />
      <Services />
      <Clients />
    </div>
  );
};

export default DigitalDealer;
