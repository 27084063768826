import React from "react";
import icon1 from "../../images/pr1.png";
import icon2 from "../../images/pr2.png";
import icon3 from "../../images/pr3.png";

const PricingSupport = () => {
  return (
    <div className="">
      <div className="w-full px-6 py-20 m-auto max-w-7xl md:px-10 ">
        <div className="flex flex-col items-center justify-center w-full">
          <h1 className="md:text-4xl text-2xl uppercase montserrat text-[#454646] font-bold  text-center  mb-4 ">
            pricing & support
          </h1>
          <p className="mb-16 text-lg font-light text-center lg:text-2xl">
            Our collective buying power through one of the largest technology
            distributors guarantees best pricing and support.
          </p>
          <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
            {cards.map(({ id, title,desc, icon }) => {
              return (
                <div
                  key={id}
                  className="flex flex-col items-center p-10 transition-all duration-500 bg-white border rounded-xl solutionn_card hover:-translate-y-3 md:hover:-translate-y-3"
                >
                  <div className={`flex justify-center rounded-full mb-4`}>
                    <img src={icon} alt="" className="w-20 h-20 p-1 rounded-xl " />
                  </div>
                  <h3 className="mb-2 text-2xl font-semibold text-center text-gray-900">
                    {title}
                  </h3>
                  <p className="text-lg font-light text-center lg:text-xl">
                    {desc}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSupport;

const cards = [
  {
    id: 1,
    title: "500+",
    desc: `COMPANIES THAT CHOOSE DEALERSYS`,
    icon: icon1,
  },
  {
    id: 2,
    title: "$20M",
    desc: `SAVINGS FOR HUNDREDS OF CUSTOMERS`,
    icon: icon2,
  },
  {
    id: 3,
    title: "$100K-3M",
    desc: `MONTHLY BILLING ACROSS HUNDREDS OF PROVIDERS`,
    icon: icon3,
  },
];
