import React from "react";

const Services = () => {
  return (
    <div  className="w-full px-6 py-32 m-auto max-w-7xl md:px-10">
      <div className="flex flex-col items-center justify-center w-full">
        <h1 className="md:text-4xl text-2xl montserrat text-[#454646] font-bold md:mb-12 text-center  mb-12 uppercase">
          SERVICES
        </h1>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          {cards.map(({ id, title, desc, iconClr }) => {
            return (
              <div
                key={id}
                className="flex flex-col items-center w-full p-10 transition-all duration-500 border solutionn_card hover:-translate-y-3 md:hover:-translate-y-2 rounded-xl"
              >
                <div
                  className={`${iconClr} text-3xl text-white solutionn_icon flex justify-center items-center shadow-xl mb-5 w-14 h-14  rounded-full`}
                >
                  {id}
                </div>
                <h3 className="mb-4 text-lg font-semibold text-center text-gray-900">
                  {title}
                </h3>
                <p className="text-lg font-light text-center lg:text-xl">
                  {desc}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;

const cards = [
  {
    id: 1,
    title: "TECH ASSESSMENT",
    desc: `Free technology assessment to help you design the
    perfect solution for your dealership utilizing a portfolio
    of hundreds of providers & thousands of solutions.`,
    iconClr: "colorA",
  },
  {
    id: 2,
    title: "SEAMLESS INTEGRATION",
    desc: `DealerSys manages the technical implementation and
    vendor relationships for a stress-free transition that
    takes your dealership to the next level.`,
    iconClr: "colorB",
  },
  {
    id: 3,
    title: "EXECUTIVE SUPPORT",
    desc: `As Platinum Partners we have access to executive level
    escalation paths surpassing tier 3 customer support for
    best-in-class service 24/7/365 from pre to post-install.`,
    iconClr: "colorC",
  },
];
