import React from "react";
import icon1 from "../../images/sol1.png";
import icon2 from "../../images/sol2.png";
import icon3 from "../../images/sol3.png";
import icon4 from "../../images/sol4.png";
import icon5 from "../../images/sol5.png";
import icon6 from "../../images/sol6.png";

const Solutions = () => {
  return (
    <div className="bg-[#f8f8f8]">
      <div className="w-full px-6 py-20 m-auto max-w-7xl md:px-10 ">
        <div className="flex flex-col items-center justify-center w-full">
          <h1 className="md:text-4xl text-2xl montserrat text-[#454646] font-bold  text-center  mb-4 uppercase">
            SOLUTIONS
          </h1>
          <p className="mb-16 text-lg font-light text-center lg:text-2xl">
            A customized approach that personalize your dealership's technology
            giving you unprecedented insight into your business.
          </p>
          <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
            {cards.map(({ id, title, icon }) => {
              return (
                <div
                  key={id}
                  className="flex flex-col items-center p-10 transition-all duration-500 bg-white border rounded-xl solutionn_card hover:-translate-y-3 md:hover:-translate-y-3"
                >
                  <div className={`flex justify-center`}>
                    <img src={icon} alt="" className="w-20 mb-3" />
                  </div>
                  <h3 className="mb-4 text-lg font-semibold text-center text-gray-900">
                    {title}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;

const cards = [
  {
    id: 1,
    title: "Dynamic Predictive Advertising",

    icon: icon1,
  },
  {
    id: 2,
    title: "CRM/DMS Integrated Phone Systems",

    icon: icon2,
  },
  {
    id: 3,
    title: "Call, text and video tracking",

    icon: icon3,
  },
  {
    id: 4,
    title: "Network Infrastructure",

    icon: icon4,
  },
  {
    id: 5,
    title: "Digital Assistants",

    icon: icon5,
  },
  {
    id: 6,
    title: "Advanced AI Solutions",

    icon: icon6,
  },
];
