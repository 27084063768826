import React from "react";

const DealsysTouch = () => {
  return (
    <div className="h-[60vh] dealsysTouch_bg flex justify-center items-center">
      <div className="px-6 m-auto text-white max-w-7xl md:px-8">
        <h1 className="mb-5 text-xl font-bold text-center lg:text-7xl sm:text-4xl md:leading5">
          THE DEALERSYS TOUCH
        </h1>
        <p className="text-lg font-light text-center lg:text-2xl">
          DealerSys is the number one technology advocate for your dealership.
          We offer fully supported enterprise solutions from hundreds of
          providers that integrate communications platforms with the CRM/DMS,
          streamline operations, and increase sales and marketing visibility.
        </p>
      </div>
    </div>
  );
};

export default DealsysTouch;
