import React from "react";

const Hero = () => {
  return (
    <div className=" h-[70vh] md:h-[91vh] digital_hero_bg">
      <div className="px-6 pt-48 m-auto text-white md:pt-60 max-w-7xl md:px-8">
        <h1 className="mb-3 text-3xl font-bold md:mb-5 lg:text-7xl sm:text-4xl md:leading5">
          Bringing Your Dealership
        </h1>
        <h3 className="mb-5 text-2xl font-medium lg:text-4xl md:text-2xl">
          INTO THE NOW
        </h3>
        <p className="max-w-[650px] text-lg font-light lg:text-2xl ">
          Stop Procrastinating! With seamless transitions and unprecedented
          support now is the time to adopt technology that will allow your
          dealership to thrive!
        </p>
      </div>
    </div>
  );
};

export default Hero;
