import React from "react";
import Layout from "../components/Layout";
import Speak from "../components/Speak";
import DigitalDealer from "../views/DigitalDealer";

const DigitalDealerPage = ({location}) => {
  return (
    <Layout location={location}>
      <DigitalDealer />
      <Speak location={location}/>
    </Layout>
  );
};

export default DigitalDealerPage;
