import React from "react";
import icon1 from "../../images/sol1.png";
import icon2 from "../../images/sol2.png";
import icon4 from "../../images/sol4.png";
const BookMeeting = () => {
  return (
    <div className="">
      <div className="">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 md:grid-cols-2">
          <div className="relative transition-all duration-200 transform ">
            <div className="absolute w-full min-height gardient1 rounded-3xl z_1"></div>

            <div className="px-4 py-10 bg-white shadow-xl card1_transform tans min-height rounded-3xl">
              <div className="mt-3">
                <div className="flex justify-center mb-4">
                  <img src={icon1} alt="" className="w-20" />
                </div>
                <h1 className="pl-4 mb-8 text-lg font-medium text-center min-h-[80px]">
                  Book a Dynamic, Personalized Advertising Demo
                </h1>
              </div>

              <div className="flex self-end justify-center px-4 ">
                <div>
                  <a
                    href="https://calendly.com/dealersys/predictive-dynamic-advertising-digital-dealer-contest"
                    target="_blank"
                  >
                    <span className="font-medium text-sm bg-black text-white px-4 py-3.5 rounded-lg transition-all hover:shadow-lg">
                      Schedule an Demo
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="relative transition-all duration-200 transform ">
            <div className="absolute w-full min-height gardient1 rounded-3xl z_1"></div>

            <div className="px-4 py-10 bg-white shadow-xl card1_transform tans min-height rounded-3xl">
              <div className="mt-3">
                <div className="flex justify-center mb-4">
                  <img src={icon2} alt="" className="w-20" />
                </div>
                <h1 className="pl-4 mb-8 text-lg font-medium text-center min-h-[80px]">
                  Book a CRM/DMS Integrated Telephony Demo
                </h1>
              </div>

              <div className="flex self-end justify-center px-4 ">
                <div>
                  <a
                    href="https://calendly.com/dealersys/general-discussion"
                    target="_blank"
                  >
                    <span className="font-medium text-sm bg-black text-white px-4 py-3.5 rounded-lg transition-all hover:shadow-lg">
                      Schedule an Demo
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="relative transition-all duration-200 transform ">
            <div className="absolute w-full min-height gardient1 rounded-3xl z_1"></div>

            <div className="px-4 py-10 bg-white shadow-xl card1_transform tans min-height rounded-3xl">
              <div className="mt-3">
                <div className="flex justify-center mb-4">
                  <img src={icon4} alt="" className="w-20" />
                </div>
                <h1 className="pl-4 mb-8 text-lg font-medium text-center min-h-[80px]">
                  Book a Network Assessment
                </h1>
              </div>

              <div className="flex self-end justify-center px-4 ">
                <div>
                  <a
                    href="https://calendly.com/dealersys/solutions-discussion"
                    target="_blank"
                  >
                    <span className="font-medium text-sm bg-black text-white px-4 py-3.5 rounded-lg transition-all hover:shadow-lg">
                      Schedule an Demo
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookMeeting;
