import React from "react";
import Overview from "../../images/DealersysOverview.pdf";
import InteractiveTelCRMDMSIntegrated from "../../images/InteractiveTelCRMDMSIntegrated.pdf";
import GoToCRMIntegratedTelephony from "../../images/GoToCRMIntegratedTelephony.pdf";
import DynamicAdvertisingOverview from "../../images/DynamicAdvertisingOverview.pdf";
import pdfIcon from "../../images/pdf-icon-4.png";
import { Link } from "gatsby";
import BookMeeting from "./BookMeeting";
import { StaticImage } from "gatsby-plugin-image";

const Intro = () => {
  return (
    <div className="w-full bg-[#f8f8f8]">
      <div className="px-6 m-auto py-28 max-w-7xl md:px-8">
        <h1 className="md:text-4xl text-2xl uppercase montserrat text-[#454646] font-bold    mb-7 ">
        DEALERSYS @ NADA 2023
        </h1>
        <p className="mb-20 text-lg font-light lg:text-2xl">
        Thanks for visiting booth 1611 at the show!
        </p>
        <p className="mb-16 text-lg font-normal lg:text-2xl">
          For more information on our technology and solutions please download
          the attached files:
        </p>
        <div className="grid grid-cols-1 gap-10 md:grid-cols-4">
          {data.map((item, index) => {
            return (
              <div key={index}>
                <a href={item.file} download target={"_blank"}>
                  <div>
                    <div className="flex justify-center mb-4 ">
                      <img src={pdfIcon} alt="" className="w-20" />
                    </div>
                    <span className="block text-lg font-normal text-center text-gray-900 cursor-pointer">
                      {item.title}
                    </span>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex items-center justify-center pt-32 pb-28 win_bg">
        <div className="px-6 mx-auto max-w-7xl">
          {/* <h1 className="text-2xl font-bold text-center text-white uppercase md:text-5xl montserrat mb-7 ">
            WIN A TRIP TO THE FLORIDA KEYS!!!*
          </h1>
          <p className="mb-20 text-lg font-light text-center text-white lg:text-2xl">
            Win a luxury stay in a 3 bed, 2 bath waterfront
            <a
              href="https://www.airbnb.com/rooms/724469449400272209?adults=1&children=0&infants=0&location=Sugarloaf%20Key%2C%20FL%2C%20USA&check_in=2022-12-01&check_out=2022-12-31&federated_search_id=cdb0cf54-d25a-4d8d-a689-14ea4a05d8d6&source_impression_id=p3_1665497605_fuHgoovY%2FDK9JPdg"
              target={"_blank"}
              className="px-1 underline"
            >
              home
            </a>
            in the Florida Keys, including airfare! Set and attend a 30-minute
            demo or discussion to be entered into the sweepstakes. We will be
            giving away up to two trips for those who have set and attended
            demos.
          </p> */}
          <div className="mb-24">
            <BookMeeting />
          </div>
          {/* <div className="flex justify-center">
            <Link to="/sweepsakes-rules">
              <span className="px-8 py-4 ml-2 text-lg text-black capitalize transition-all bg-white rounded-full hover:shadow-lg NexaTextDemoLight">
                View Sweepstakes Rules
              </span>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className="flex justify-center">
        <StaticImage
          src="../../images/IMG_1014.jpg"
          alt="Florida"
          className="w-full"
        />
      </div> */}
    </div>
  );
};

export default Intro;
const data = [
  {
    title: "DealerSys Overview ",
    file: Overview,
  },
  {
    title: "Dynamic Advertising Overview",
    file: DynamicAdvertisingOverview,
  },
  {
    title: "InteractiveTel CRM/DMS Integrated Telephony",
    file: InteractiveTelCRMDMSIntegrated,
  },
  {
    title: "GoTo CRM Integrated Telephony",
    file: GoToCRMIntegratedTelephony,
  },
];
