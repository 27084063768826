import React from "react";
import icon1 from "../../images/1.png";
import icon2 from "../../images/2.png";
import icon3 from "../../images/3.png";
const ConnectCustomers = () => {
  return (
    <div className="w-full">
      <div className="px-6 m-auto py-28 max-w-7xl md:px-8">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
          {data.map((item, index) => {
            return (
              <div key={index}>
                <div className="flex justify-center mb-4">
                  <img src={item.icon} alt="" />
                </div>
                <p className="text-xl font-normal text-center text-gray-900">
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConnectCustomers;
const data = [
  {
    title: "Easy-To-Use App Tracks All Calls, Texts, Photo and Video",
    icon: icon1,
  },
  {
    title:
      "Comprehensive Marketing and Employee Data in Real-Time into your CRM",
    icon: icon2,
  },
  {
    title: "No More Call Forwarding - Directly Connect Every Time",
    icon: icon3,
  },
];
